import React, { useEffect } from 'react';
import Link from 'next/link';
import Olark from 'react-olark-plugin';
import CookieConsent from 'react-cookie-consent';
import { useInView } from 'react-intersection-observer';

import styles from './footer.module.scss';
import useLanguage from '../_hooks/useLanguage';
import useApp from '../_hooks/useApp';
import Navigation from '../navigation/navigation';
import Toolbar from './toolbar';

const Footer = () => {
	const { t } = useLanguage();
	const { appData: { properties, supportChatOpen, overlayPanel }, appDispatch, appReset } = useApp();

	const { ref, inView } = useInView({
		threshold: 0,
		triggerOnce: true
	});

	const navigationLeft = [
		{ href: '/mojedrazbe-pro', text: t('navAbout'), type: 'link' },
		{ href: '/cenik', text: t('navPricelist'), type: 'link' },
		{ href: '/o-nas/kontakt', text: t('navContact'), type: 'link' },
		{ href: '/o-nas/oglasevanje', text: t('navAdvertisment'), type: 'link' },
		{ href: null, text: t('navSupport'), type: 'button', onClick: () => appDispatch({ type: 'SUPPORT_CHAT_TOGGLE' }) }
	];

	const navigationBottom = [
		{ href: '/o-nas/splosni-pogoji-uporabe', text: t('navTerms'), type: 'link' },
		{ href: '/o-nas/politika-zasebnosti-in-varstvo-osebnih-podatkov', text: t('navPrivacy'), type: 'link' }
	];

	// Olark help
	useEffect(() => {
		window.olark(supportChatOpen ? 'api.box.expand' : 'api.box.hide');
		window.olark('api.box.onShrink', () => appDispatch({ type: 'SUPPORT_CHAT_TOGGLE', payload: false })); // when olark close button is clicked
	}, [supportChatOpen]);

	// <Language />
	// <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><span className="hideText">Mojedrazbe.com @ Instagram</span><i className="icon instagram" /></a>
	// // CookieConsent > acceptOnScroll

	return (
		<div className={(overlayPanel && overlayPanel.name) ? `footer-${overlayPanel.name}` : ''}>
			<CookieConsent
				location="none"
				cookieName="cc-accept"
				buttonText={t('cookies_consent_btn_ok')}
				expires={150}
				disableStyles
			>
				{t('cookies_consent_text')}
				<Link href="/o-nas/politika-zasebnosti-in-varstvo-osebnih-podatkov"><a>{t('btnDetailed')}</a></Link>
			</CookieConsent>
			<Olark
				siteId="6903-235-10-9172"
				systemConfig={{
					hb_primary_color: '#6872E3',
					hb_chatbox_size: 'md',
					hb_show_as_tab: true,
					hb_show_button_text: false
				}}
			/>
			<Toolbar />
			<footer className={styles.default} ref={ref}>
				{inView && (
					<>
						<div className={styles.top}>
							<div className="container">
								<div className="row">
									<div className="col-12 footer-wrapper">
										<div>
											<Navigation list={navigationLeft} />
										</div>

										<div>
											<div className={styles.social}>
												<a href="https://www.facebook.com/mojedrazbe/" target="_blank" rel="noopener noreferrer"><span className="hideText">Mojedrazbe.com @ Facebook</span><i className="icon facebook" /></a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.bottom}>
							<div className="container">
								<div className="row">
									<div className="col-12">
										<Link href="/"><a className={`${styles.logo} hideText`}>Mojedrazbe.com</a></Link>
										<p>{t('footerCopyrights')}, <span className={styles.reset}>v{properties.version}<button type="button" onClick={() => appReset(true)}>reset</button></span></p>
										<Navigation list={navigationBottom} />
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</footer>
		</div>
	);
};

export default Footer;
